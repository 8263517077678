import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as IcClose } from 'src/assets/svg/ic_close_drawer.svg';
import { ReactComponent as LogoSvg } from 'src/assets/image/fitopia/logo.svg';
import { ReactComponent as LogoWhiteSvg } from 'src/assets/image/fitopia/logo-white.svg';
import { ReactComponent as MailSvg } from 'src/assets/image/fitopia/mail-icon.svg';
import { ReactComponent as MailWhiteSvg } from 'src/assets/image/fitopia/mail-white-icon.svg';
import { actionChangeBackground, ACTION_TYPES, ACTIVE_TYPES } from 'src/modules/Home/redux/home.action';
import { isMobile } from 'react-device-detect';
import { HeaderFrame } from './CustomHeader.styled';
import HeaderMobile from './components/HeaderMobile';

function CustomHeader() {
    const { isFirstBackground, active, value } = useSelector((state: any) => state.home);
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);

    const openMenu = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };

    return (
        <>
            {!isMobile ? (
                <HeaderFrame>
                    <div className="custom-header">
                        <div
                            onClick={() => {
                                dispatch({
                                    type: ACTION_TYPES.ACTIVE_HEADER,
                                    payload: { active: ACTIVE_TYPES.DEMO, value: 0 },
                                });
                                dispatch(actionChangeBackground(true));
                            }}
                            className={`item-nav ${value % 2 === 1 && value !== 0 ? 'type-1' : 'type-2'} ${
                                active === ACTIVE_TYPES.DEMO ? 'active-demo' : ''
                            } ${isFirstBackground ? 'white-text' : ''}`}
                        >
                            DEMO
                        </div>
                        <div
                            onClick={() => {
                                dispatch({
                                    type: ACTION_TYPES.ACTIVE_HEADER,
                                    payload: { active: ACTIVE_TYPES.ROADMAP, value: 9 },
                                });
                                dispatch(actionChangeBackground(false));
                            }}
                            className={`item-nav ${active === ACTIVE_TYPES.ROADMAP ? 'active-roadmap' : ''} ${
                                isFirstBackground ? 'white-text' : ''
                            }`}
                        >
                            ROADMAP
                        </div>
                        <div className="logo">{isFirstBackground ? <LogoWhiteSvg /> : <LogoSvg />}</div>
                        <div
                            onClick={() => {
                                dispatch({
                                    type: ACTION_TYPES.ACTIVE_HEADER,
                                    payload: { active: ACTIVE_TYPES.CORETEAM, value: 10 },
                                });
                                dispatch(actionChangeBackground(false));
                            }}
                            className={`item-nav ${active === ACTIVE_TYPES.CORETEAM ? 'active-coreteam' : ''} ${
                                isFirstBackground ? 'white-text' : ''
                            }`}
                        >
                            CORE TEAM
                        </div>
                        <div className={`item-nav white-paper ${isFirstBackground ? 'white-text' : ''}`}>
                            <div className={`item-nav ${isFirstBackground ? 'white-text' : ''}`}>WHITE PAPER</div>
                            <div
                                className="contact"
                                onClick={() => {
                                    dispatch({
                                        type: ACTION_TYPES.ACTIVE_HEADER,
                                        payload: { active: ACTIVE_TYPES.CONTACT_DESKTOP, value: 11 },
                                    });
                                    dispatch(actionChangeBackground(false));
                                }}
                            >
                                {isFirstBackground ? <MailWhiteSvg /> : <MailSvg />}
                            </div>
                        </div>
                    </div>
                </HeaderFrame>
            ) : (
                <HeaderMobile />
            )}
        </>
    );
}

export default CustomHeader;
