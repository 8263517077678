import React from 'react';
import ReactDOM from 'react-dom';
import { FixedGlobalStyle } from 'src/theme';
import App from './app/App';
import './assets/fonts/Poppins/Poppins-Regular.ttf';
import './assets/fonts/Poppins/Poppins-Medium.ttf';
import './assets/fonts/Poppins/Poppins-Bold.ttf';

require('dotenv').config();

ReactDOM.render(
    <React.StrictMode>
        <FixedGlobalStyle />
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
);
