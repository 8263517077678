import styled, { ITheme } from 'styled-components';
import { Row } from 'antd';

export const Wrapper = styled(Row)`
    ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToLarge`
        // .ant-menu.ant-menu-dark,
        // .ant-menu-dark .ant-menu-sub,
        // .ant-menu.ant-menu-dark .ant-menu-sub {
        //     background: rgb(20, 20, 22);
        // }

        // .menu-mobile {
        //     bottom: 0;
        // }


        // .ant-menu-item-selected,
        // .ant-menu-item-active {
        //     background: rgb(20, 20, 22) !important;

        //     .ant-menu-title-content {
        //         color: ${theme.text7} !important;
        //     }
        // }

        // .ant-menu-title-content {
        //     font-weight: 600 !important;
        //     font-size: 16px;
        // }

        // .ant-menu-submenu-title,
        // .ant-menu-item.ant-menu-item-only-child
        // {
        //     padding-left: 0 !important;
        //     margin-top: 16px;
        // }

        // .ant-menu-submenu-arrow {
        //     right: 0 !important;
        // }
        // .custom-submenu {
        //     .ant-menu-item {
        //         font-size: 14px;
        //         font-weight: 600;
        //         border-top: 1px solid #525252;
        //     }
        // }

        // .ant-menu-submenu-open {
        //     .submenu-main {
        //         color: ${theme.text7} !important;
        //     }
        // }
        // .ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content,
        // .ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
        //     color: rgb(94, 99, 114);
             
        //     a {
        //         color: rgb(94, 99, 114);
        //     }
        // }

        // .custom-submenu {
        //     padding-left: 20px !important;
        // }
        width: 100%;
        height: 100%;
        position: relative;
        background: linear-gradient(#48E4C5, #3DE5CC);
        backdrop-filter: blur(240.144px);
        .list-menu {
            position: absolute;
            bottom: 80px;
            right: 40px;
            .menu-item {
                margin-top: 15px;
                text-align: right;
                color: #000000;
                display: flex;
                justify-content: right;
                flex-wrap: wrap;

                span {
                    font-family: 'Kanit';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 30px;
                }
                &.active {
                    background: linear-gradient(187deg,#FF992E -15.77%,#F88138 4.4%,#E74453 45.64%,#DA1467 75.89%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent;

                    span {
                        border-bottom: 3px solid #E74453;
                    }
                }
            }
        }
    `}
`;
