import React, { useEffect, useMemo } from 'react';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router';
import { MainRoute } from 'src/modules';
import { ThemeProvider, ThemedGlobalStyle } from 'src/theme';
import Header from 'src/components/Header';
import Modal from 'src/components/Modal';
import Loading from 'src/components/Loading';
import AOS from 'aos';
import ScrollToTop from '../components/ScrollToTop/ScrollToTop';
import withApp from './App.enhance';
import './reset.scss';
import 'antd/dist/antd.css';
// import '@solana/wallet-adapter-ant-design/styles.css';
import 'aos/dist/aos.css';
// import 'swiper/swiper.min.css';
// import 'swiper/modules/pagination/pagination.min.css';

const history = createBrowserHistory();

const App: React.FunctionComponent = () => {
    useEffect(() => {
        AOS.init({
            duration: 2000,
        });
    }, []);
    return (
        <ThemeProvider>
            <ThemedGlobalStyle />
            <Router history={history}>
                <ScrollToTop>
                    <Header />
                    <MainRoute />
                    {/* <Footer /> */}
                    <Modal />
                    <Loading />
                </ScrollToTop>
            </Router>
        </ThemeProvider>
    );
};

export default withApp(React.memo(App));
