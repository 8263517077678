import React, { useState } from 'react';
import { Menu } from 'antd';
import { NavLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { actionActiveKeyMobile, ACTIVE_TYPES } from 'src/modules/Home/redux/home.action';
import { Wrapper } from './MenuMobile.styled';
import { menuMobileItem, IMenuMobile, ISubmenu } from '../HardData';

const { SubMenu } = Menu;

const MenuMobile = ({ onClose }: any) => {
    const { isFirstBackground, active, value } = useSelector((state: any) => state.home);
    const dispatch = useDispatch();
    const [collapsed, setCollapsed] = useState(false);
    const handleClick = (e: any) => {
        console.log('click ', e);
    };
    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };
    return (
        <Wrapper>
            <div className="list-menu">
                <div
                    className={`menu-item ${active === ACTIVE_TYPES.DEMO ? 'active' : ''}`}
                    onClick={() => {
                        dispatch(actionActiveKeyMobile('1'));
                        onClose();
                    }}
                >
                    <span>DEMO</span>
                </div>
                <div
                    className={`menu-item ${active === ACTIVE_TYPES.ROADMAP ? 'active' : ''}`}
                    onClick={() => {
                        dispatch(actionActiveKeyMobile('9'));
                        onClose();
                    }}
                >
                    <span>ROADMAP</span>
                </div>
                <div
                    className={`menu-item ${active === ACTIVE_TYPES.CORETEAM ? 'active' : ''}`}
                    onClick={() => {
                        dispatch(actionActiveKeyMobile('10'));
                        onClose();
                    }}
                >
                    <span>CORE TEAM</span>
                </div>
                <div className={`menu-item ${''}`}>
                    <span>WHITE PAPER</span>
                </div>
                <div
                    className={`menu-item ${''}`}
                    onClick={() => {
                        window.open('/Fitopia-PrivacyPolicy.pdf');
                        onClose();
                    }}
                >
                    <span>PRIVACY POLICY</span>
                </div>
                <div
                    className={`menu-item ${active === ACTIVE_TYPES.CONTACT ? 'active' : ''}`}
                    onClick={() => {
                        dispatch(actionActiveKeyMobile('11'));
                        onClose();
                    }}
                >
                    <span>CONTACT US</span>
                </div>
            </div>
        </Wrapper>
    );
};

export default MenuMobile;
