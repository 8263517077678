import styled, { ITheme } from 'styled-components';
import { Row } from 'antd';

export const HeaderFrame = styled(Row)`
    z-index: 2;
    position: fixed;
    top: 0;
    width: 100%;
    .custom-header {
        max-width: 745px;
        width: 100%;
        margin: 0 auto;
        margin-top: 15px;
        width: 100%;
        display: flex;
        justify-content: space-between;

        .contact {
            position: absolute:
            right: 30px;
            z-index: 100;
        }

        .white-paper {
            position: relative;
            .contact {
                position: absolute;
                right: -100px;
                top: -10px;
            }
        }

        .logo {
            transition: 0.3s ease;
            cursor: pointer;
            svg {
                width: 195px;
                height: 45px;
            }
            &:hover {
                transform: scale(1.03);
            }
        }

        .item-nav {
            font-family: 'Kanit';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            color: #000000;
            cursor: pointer;
            transition: 0.3s ease;
            
            &.white-paper {
                transition: unset;
                &:hover {
                    transform: unset;
                }

                div {
                    transition: 0.3s ease;
                    &:hover {
                        transform: scale(1.03);
                    }
                }
                .contact {
                    transition: 0.3s ease;
                    &:hover {
                        transform: scale(1.03);
                    }
                }
            }
            &:hover {
                transform: scale(1.03);
            }
            &.white-text {
                color: #ffffff;
            }
            &.active-roadmap {
                font-family: 'Kanit';
                font-style: normal;
                font-weight: 900;
                font-size: 20px;
                line-height: 30px;
                text-align: center;
                text-decoration-line: underline;
                background: linear-gradient(211.43deg, #ff992e -15.77%, #f88138 4.4%, #e74453 45.64%, #da1467 75.89%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                border-bottom: 3px solid #e74453;
                height: fit-content;
            }
            &.active-coreteam {
                background: linear-gradient(
                    40.45deg,
                    #015a5e 4.26%,
                    #066c6b 19%,
                    #139b8d 48.46%,
                    #28e7c2 90.06%,
                    #29e9c4 90.92%
                );
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                border-bottom: 3px solid #139b8d;
                height: fit-content;
            }
            &.active-demo {
                font-weight: 900;
                text-decoration-line: underline;

                &.type-1 {
                    background: linear-gradient(
                        211.43deg,
                        #ff992e -15.77%,
                        #f88138 4.4%,
                        #e74453 45.64%,
                        #da1467 75.89%
                    );
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent;
                    border-bottom: 3px solid #e74453;
                    height: fit-content;
                }
                &.type-2 {
                    background: linear-gradient(
                        40.45deg,
                        #015a5e 4.26%,
                        #066c6b 19%,
                        #139b8d 48.46%,
                        #28e7c2 90.06%,
                        #29e9c4 90.92%
                    );
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent;
                    border-bottom: 3px solid #139b8d;
                    height: fit-content;
                }
                &:hover {
                    transform: scale(1.06);
                }
            }
        }
    }

    ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToMedium`
        .menu-desktop {
            display: none !important;
        }
        .custom-header {
            margin-top: 0;
        }
        .menu-mobile {
            width: 100%;
            padding: 10px 20px;
            border-bottom: 1px solid red;
        }
    `}
`;
