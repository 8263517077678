import { truncateSync } from 'fs';
import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/es/storage';
import { ACTION_TYPES, ACTIVE_TYPES } from './home.action';

const initialState = {
    isFirstBackground: true,
    active: 'demo',
    value: 0,
    activeKeyMobile: '',
};
const walletReducer: any = (
    state = initialState,
    action: {
        type: string;
        payload: any;
    },
) => {
    switch (action.type) {
        case ACTION_TYPES.UPDATE_HEADER:
            return {
                ...state,
                isFirstBackground: action.payload,
            };
        case ACTION_TYPES.UPDATE_VALUE:
            return {
                ...state,
                value: action.payload,
            };
        case ACTION_TYPES.ACTIVE_HEADER:
            return {
                ...state,
                active: action.payload.active,
                value: action.payload.value,
            };
        case ACTION_TYPES.ACTIVE_KEY_MOBILE:
            return {
                ...state,
                activeKeyMobile: action.payload,
            };
        default:
            return state;
    }
};

const persistConfig = {
    key: 'home',
    storage,
    whitelist: [],
    stateReconciler: autoMergeLevel2,
};
export default persistReducer(persistConfig, walletReducer);
