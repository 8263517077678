import styled, { ITheme } from 'styled-components';

export const Wrapper = styled.div`
    ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToMedium`
    z-index: 100;
    position: fixed;
    top: 50px;
    width: 100%;
    background: transition;    
    min-height: 60px;
        .menu-mobile {
            position: relative;
            min-height: 60px;
            .btn-menu-mobile {
                position: absolute;
                right: 48px;
                top: 5px;
                background: none;
                border: none;
                margin-left: 8px;

                &.ant-btn {
                    padding: 0;
                }
            }

            .logo-mobile {
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                svg {
                    width: 148px;
                    height: 34px;
                }
            }
        }
        .menu-desktop {
            display: none !important;
        }
    `}
`;
