/* eslint-disable react/jsx-fragments */
import React, { useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

function ScrollToTop({ history, children }: any) {
    useEffect(() => {
        const listen = history.listen(() => {
            // window.scrollTo(0, 0);
        });
        return () => {
            listen();
        };
    }, []);

    return <Fragment>{children}</Fragment>;
}

export default withRouter(ScrollToTop);
