export const ACTION_TYPES = {
    UPDATE_HEADER: 'UPDATE_HEADER',
    ACTIVE_HEADER: 'ACTIVE_HEADER',
    UPDATE_VALUE: 'UPDATE_VALUE',
    ACTIVE_KEY_MOBILE: 'ACTIVE_KEY_MOBILE',
};

export const ACTIVE_TYPES = {
    DEMO: 'demo',
    ROADMAP: 'roadmap',
    CORETEAM: 'coreteam',
    CONTACT: 'contact',
    CONTACT_DESKTOP: 'contact_desktop',
};

export const actionChangeBackground = (payload: boolean) => ({
    type: ACTION_TYPES.UPDATE_HEADER,
    payload,
});
export const actionActiveKeyMobile = (payload: string) => ({
    type: ACTION_TYPES.ACTIVE_KEY_MOBILE,
    payload,
});

export const actionActiveHeader = ({ active, value }: { active: string; value: number }) => ({
    type: ACTION_TYPES.ACTIVE_HEADER,
    payload: { active, value },
});

export const actionUpdateValue = ({ value }: { value: number }) => ({
    type: ACTION_TYPES.ACTIVE_HEADER,
    payload: value,
});
