import React, { Suspense } from 'react';
import { BackTop, Image } from 'antd';
import { Route, Switch } from 'react-router-dom';
import { IRouteProps } from 'src/modules';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { COMPONENT_IDS } from 'src/app/App.constant';
import enhance from 'src/modules/MainRoute/MainRoute.enhance';
import { IProps } from 'src/modules/MainRoute/MainRoute.inteface';
import Toast from 'src/components/Toast/Toast';
import scrollToTopImage from 'src/assets/image/scroll-to-top.png';

const Styled = styled.div<{ height?: number }>`
    // min-height: ${({ height }) => `${height}px`};
    // margin-top: 80px;

    .ant-back-top {
        opacity: 0.7;
    }
`;

const MainRoute = (props: IProps & any) => {
    const { routes } = props;

    if (!routes || routes.length === 0) return null;
    return (
        <Styled>
            <Toast />
            <Switch>
                <Suspense fallback={null}>
                    {routes.map((route: IRouteProps) => (
                        <Route {...route} key={route.path} />
                    ))}
                </Suspense>
            </Switch>
            <BackTop>
                <div className="scroll-to-top">
                    <Image preview={false} src={scrollToTopImage} />
                </div>
            </BackTop>
        </Styled>
    );
};

export default enhance(React.memo(MainRoute));
