import { Button, Drawer } from 'antd';
import React, { useState } from 'react';
import { ReactComponent as DrawerSvg } from 'src/assets/image/fitopia-mobile/drawer.svg';
import { ReactComponent as DrawerWhiteSvg } from 'src/assets/image/fitopia-mobile/drawer-white.svg';
import { ReactComponent as IcClose } from 'src/assets/svg/ic_close_drawer.svg';
import { ReactComponent as LogoSvg } from 'src/assets/image/fitopia/logo.svg';
import { ReactComponent as LogoWhiteSvg } from 'src/assets/image/fitopia/logo-white.svg';
import { useSelector } from 'react-redux';
import { Wrapper } from './HeaderMobile.styled';
import MenuMobile from './MenuMobile';

export default function HeaderMobile() {
    const { isFirstBackground, active, value } = useSelector((state: any) => state.home);
    const [visible, setVisible] = useState(false);
    const openMenu = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };
    return (
        <Wrapper>
            <div className="menu-mobile">
                <div className="logo-mobile">{isFirstBackground ? <LogoWhiteSvg /> : <LogoSvg />}</div>
                <Button className="btn-menu-mobile" onClick={openMenu}>
                    {isFirstBackground ? <DrawerWhiteSvg /> : <DrawerSvg />}
                </Button>
                <Drawer
                    placement="right"
                    width="100%"
                    closable
                    visible={visible}
                    key="right"
                    onClose={onClose}
                    className="drawer-header-mobile"
                    drawerStyle={{ backgroundColor: '#141416' }}
                    closeIcon={<IcClose style={{ marginTop: 8 }} width={20} height={20} />}
                >
                    <MenuMobile onClose={onClose} />
                </Drawer>
            </div>
        </Wrapper>
    );
}
